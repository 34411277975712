import React, { useState, useRef } from 'react';
import { X, Plus, ChevronDown, ChevronRight, Menu } from 'lucide-react';

const FormField = ({ label, children }) => (
  <div className="flex items-center space-x-4">
    <label className="text-gray-700 font-medium w-32">{label}</label>
    {children}
  </div>
);

const CompressionSettings = () => {
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);

  return (
    <div className="bg-white rounded-lg p-6 border border-gray-200">
      <h2 className="text-lg font-semibold mb-6">Compression Settings</h2>
      
      <div className="space-y-6">
        <FormField label="Quality Level">
          <div className="flex-1">
            <select className="w-full rounded-lg border border-gray-300 px-3 py-2 text-gray-700">
              <option value="high">High Quality (Larger Size)</option>
              <option value="medium">Medium Quality (Balanced)</option>
              <option value="low">Low Quality (Smaller Size)</option>
            </select>
          </div>
        </FormField>

        <div className="flex items-center">
          <input 
            type="checkbox" 
            id="keepMetadata" 
            className="rounded border-gray-300 text-blue-600"
          />
          <label htmlFor="keepMetadata" className="ml-2 text-gray-700">
            Keep image metadata
          </label>
        </div>

        <button
          onClick={() => setShowAdditionalOptions(!showAdditionalOptions)}
          className="flex items-center text-blue-600 hover:text-blue-700 font-medium"
        >
          {showAdditionalOptions ? (
            <ChevronDown className="w-5 h-5 mr-1" />
          ) : (
            <ChevronRight className="w-5 h-5 mr-1" />
          )}
          Additional Options
        </button>

        {showAdditionalOptions && (
          <div className="space-y-4 mt-4">
            <FormField label="Width">
              <div className="flex items-center space-x-3">
                <input 
                  type="checkbox"
                  className="rounded border-gray-300 text-blue-600"
                />
                <input
                  type="text"
                  placeholder="Leave empty"
                  className="w-40 rounded-lg border border-gray-300 px-3 py-2 text-gray-700"
                />
              </div>
            </FormField>

            <FormField label="Height">
              <div className="flex items-center space-x-3">
                <input 
                  type="checkbox"
                  className="rounded border-gray-300 text-blue-600"
                />
                <input
                  type="text"
                  placeholder="Leave empty"
                  className="w-40 rounded-lg border border-gray-300 px-3 py-2 text-gray-700"
                />
              </div>
            </FormField>

            <FormField label="Target Size">
              <div className="flex items-center space-x-3">
                <input 
                  type="checkbox"
                  className="rounded border-gray-300 text-blue-600"
                />
                <input
                  type="text"
                  placeholder="Enter size"
                  className="w-40 rounded-lg border border-gray-300 px-3 py-2 text-gray-700"
                />
                <select className="rounded-lg border border-gray-300 px-3 py-2 text-gray-700">
                  <option value="KB">KB</option>
                  <option value="MB">MB</option>
                </select>
              </div>
            </FormField>

            <FormField label="Convert to">
              <select className="w-40 rounded-lg border border-gray-300 px-3 py-2 text-gray-700">
                <option value="original">Original Format</option>
                <option value="jpg">JPG</option>
                <option value="png">PNG</option>
                <option value="webp">WebP</option>
                <option value="avif">AVIF</option>
              </select>
            </FormField>
          </div>
        )}
      </div>
    </div>
  );
};

const JPEGCompressor = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [compressedFiles, setCompressedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    handleFiles(Array.from(e.dataTransfer.files));
  };

  const handleFiles = async (files) => {
    const newFiles = [];
    for (let file of files) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        const fileData = await new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsDataURL(file);
        });
        
        newFiles.push({
          id: Date.now() + Math.random(),
          name: file.name,
          size: file.size,
          type: file.type,
          preview: fileData
        });
      }
    }
    setSelectedFiles(prev => [...prev, ...newFiles]);
  };

  const handleFromDevice = () => {
    fileInputRef.current?.click();
    setIsDropdownOpen(false);
  };

  const handlePaste = (e) => {
    const items = Array.from(e.clipboardData.items)
      .filter(item => item.type.includes('image'))
      .map(item => item.getAsFile());

    if (items.length > 0) {
      handleFiles(items);
    }
  };

  const handleClear = () => {
    setSelectedFiles([]);
    setCompressedFiles([]);
  };

  const handleCompression = async () => {
    console.log('Starting compression...');
    const compressed = [];
    for (let file of selectedFiles) {
      console.log(`Compressing file: ${file.name}`);
      const formData = new FormData();
      formData.append('image', file);
      formData.append('settings', JSON.stringify({
        format: 'jpg',
        quality: 'medium',
        keepMetadata: false,
        width: null,
        height: null,
        targetSize: null,
        sizeUnit: 'KB'
      }));

      try {
        const response = await fetch('https://jp.tess.co/api/compress', { // Updated URL for production
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const compressedSize = blob.size;
          const savedPercentage = ((file.size - compressedSize) / file.size * 100).toFixed(2);
          compressed.push({ 
            ...file, 
            compressedUrl: url, 
            compressedSize, 
            savedPercentage 
          });
          console.log(`File compressed: ${file.name}`);
        } else {
          console.error('Compression failed:', response.statusText);
        }
      } catch (error) {
        console.error('Compression error:', error);
      }
    }
    setCompressedFiles(compressed);
    console.log('Compression complete.');
  };

  const renderInitialUpload = () => (
    <div
      className={`
        border-2 border-dashed rounded-lg p-12 
        ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
        transition-colors duration-200
      `}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onPaste={handlePaste}
    >
      <div className="flex flex-col items-center space-y-6">
        <p className="text-gray-500 text-xl text-center">
          Drag & drop files here or<br />
          Paste the image from the clipboard (Ctrl-V or ⌘V).
        </p>

        <div className="relative">
          <div className="inline-flex rounded-lg overflow-hidden">
            <button 
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2.5 flex items-center space-x-2 border-r border-blue-500"
              onClick={handleFromDevice}
            >
              <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
              </svg>
              <span className="text-lg font-normal">Choose Files</span>
            </button>
            <button 
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 flex items-center"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M7 10l5 5 5-5H7z"/>
              </svg>
            </button>
          </div>

          {isDropdownOpen && (
            <div className="absolute left-0 mt-1 w-64 rounded-lg bg-blue-600 text-white shadow-lg z-10 py-2">
              <button 
                onClick={handleFromDevice}
                className="w-full px-4 py-3 hover:bg-blue-700 flex items-center space-x-3 text-lg"
              >
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M4 20h16a2 2 0 002-2V8a2 2 0 00-2-2h-7l-2-2H4a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                </svg>
                <span>From Device</span>
              </button>
              
              {["Dropbox", "Google Drive", "OneDrive", "Url"].map((source) => (
                <button
                  key={source}
                  className="w-full px-4 py-3 hover:bg-blue-700 flex items-center space-x-3 text-lg"
                >
                  <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"/>
                  </svg>
                  <span>From {source}</span>
                </button>
              ))}
            </div>
          )}
        </div>

        <p className="text-sm text-gray-500">
          Compress .JPEG, .JPG, .PNG, .GIF, .WEBP, .PDF, .tif,.SVG, .ICO, .heic.. Max 50 MB.
        </p>
      </div>
    </div>
  );

  const renderCompressedImages = () => (
    <div className="space-y-4">
      {compressedFiles.map(file => (
        <div key={file.id} className="flex items-center space-x-4 p-4 border rounded-lg">
          <img src={file.compressedUrl} alt={file.name} className="w-16 h-16 object-cover rounded-lg" />
          <div className="flex-1">
            <div className="font-medium">{file.name}</div>
            <div className="text-sm text-gray-500">
              Before: {(file.size / 1024).toFixed(2)} KB → After: {(file.compressedSize / 1024).toFixed(2)} KB
            </div>
            <div className={`text-sm ${file.savedPercentage > 0 ? 'text-green-600' : 'text-blue-600'}`}>
              {file.savedPercentage > 0 ? `Saved ${file.savedPercentage}%` : 'Compressing...'}
            </div>
          </div>
          <div className="flex space-x-2">
            <button className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded">Compare</button>
            <button className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded">Download</button>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTotalSavings = () => {
    const totalSaved = compressedFiles.reduce((acc, file) => acc + (file.size - file.compressedSize), 0);
    return (
      <div className="flex justify-between items-center p-4 bg-gray-100 rounded-lg">
        <div className="text-lg font-medium">Total Saved: {(totalSaved / 1024).toFixed(2)} KB</div>
        <div className="flex space-x-2">
          <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded">Download All</button>
          <button onClick={handleClear} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">Clear List</button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#fafafa]">
      <header className="bg-gradient-to-r from-blue-500 to-blue-800">
        <div className="max-w-7xl mx-auto flex justify-between items-center p-4">
          <div className="flex items-center">
            <span className="text-white text-2xl font-bold bg-black/20 px-3 py-1 rounded">
              JPEG
            </span>
            <span className="text-white text-2xl ml-2">
              Compressor<span className="text-sm">.com</span>
            </span>
          </div>
          <nav className="flex space-x-6">
            <a href="/" className="text-white hover:text-blue-200">HOME</a>
            <a href="/about" className="text-white hover:text-blue-200">ABOUT</a>
          </nav>
        </div>
      </header>

      <main className="flex-grow">
        <div className="max-w-6xl mx-auto px-4 py-12">
          <h1 className="text-4xl font-light text-center text-gray-800 mb-4">
            JPEG Compressor
          </h1>
          <p className="text-gray-600 text-center mb-12">
            Jpeg compressor is free online image optimization tool that can compress JPEG, PNG, JPG, SVG,
            and WEBP images.
          </p>

          <div className="space-y-6">
            {selectedFiles.length === 0 ? (
              renderInitialUpload()
            ) : (
              <>
                <div className="border-2 border-dashed rounded-lg p-8">
                  <div className="flex items-start justify-between">
                    <div className="flex items-start space-x-4 overflow-x-auto pb-4 flex-grow">
                      {selectedFiles.map(file => (
                        <div 
                          key={file.id} 
                          className="relative flex-shrink-0 w-24 h-24 group"
                        >
                          <img 
                            src={file.preview} 
                            alt={file.name}
                            className="w-full h-full object-cover rounded-lg"
                          />
                          <button
                            onClick={() => removeFile(file.id)}
                            className="absolute -top-2 -right-2 bg-white shadow-md hover:bg-gray-100 
                                     rounded-full p-1 text-gray-500"
                          >
                            <X size={14} />
                          </button>
                        </div>
                      ))}
                    </div>

                    <div className="relative flex-shrink-0 ml-4">
                      <button
                        onClick={handleFromDevice}
                        className="w-24 h-24 bg-blue-50 hover:bg-blue-100 
                                 rounded-lg flex items-center justify-center transition-colors"
                      >
                        <Plus size={24} className="text-blue-600" />
                      </button>
                    </div>
                  </div>
                </div>

                <CompressionSettings />

                <button
                  onClick={handleCompression}
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg 
                           transition-colors font-medium"
                >
                  Start Compression
                </button>

                {compressedFiles.length > 0 && renderTotalSavings()}
                {renderCompressedImages()}
              </>
            )}
          </div>
        </div>
      </main>

      <footer className="bg-blue-900 h-20" />

      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept="image/*"
        className="hidden"
        onChange={(e) => handleFiles(Array.from(e.target.files))}
      />
    </div>
  );
};

export default JPEGCompressor;
